import React from 'react';
import { graphql } from 'gatsby';
import { InformationBox, InfoboxColumn, InfoboxSection } from './information-box';

const park = process.env.GATSBY_PARK;

export const InfoboxWildlife = (query) => {
  // Change the intro to the native name depending on the area
  const nativeName = park === 'uktnp' ? 'Pitjantjatjara/Yankunytjatjara name' : 'We say';

  // Drupal only exposes the list item key, not the value, so we duplicate it here and reassign.
  // The values must align with the values in the corresponding Drupal field:
  // /admin/structure/paragraphs_type/information_wildlife/fields/paragraph.information_wildlife.field_conservation_status
  const conservationStatusMap = {
    vulnerable: 'Vulnerable',
    endangered: 'Endangered',
    critically_endangered: 'Critically endangered',
  },
    // /admin/structure/paragraphs_type/information_wildlife/fields/paragraph.information_wildlife.field_population_numbers
    populationNumbersMap = {
      abundant: 'Abundant',
      common: 'Common',
      rare: 'Rare',
    },
    // /admin/structure/paragraphs_type/information_wildlife/fields/paragraph.information_wildlife.field_wildlife_residency_status
    residencyStatusMap = {
      endemic: 'Endemic',
      migrant: 'Migrant',
    };

  // If no fields are set, don't render the component
  return !query.field_field_native_name && !query.field_scientific_name ? (
    <React.Fragment key={query.id}></React.Fragment>
  ) : (
    <InformationBox>
      {/* Column 1 */}
      <InfoboxColumn>
        {query.field_scientific_name?.value && (
          <InfoboxSection sectionTitle="Scientific name">
            <p>
              <em>{query.field_scientific_name?.value}</em>
            </p>
          </InfoboxSection>
        )}
        {query.field_native_name && (
          <InfoboxSection sectionTitle={nativeName}>
            <p>{query.field_native_name}</p>
          </InfoboxSection>
        )}
        {(query.field_length || query.weight || query.field_wingspan) && (
          <InfoboxSection sectionTitle="Size">
            {query.field_length && <p>Length: {query.field_length}</p>}
            {query.field_weight && <p>Weight: {query.field_weight}</p>}
            {query.field_wingspan && <p>Wingspan: {query.field_wingspan}</p>}
          </InfoboxSection>
        )}
      </InfoboxColumn>
      {/* Column 2 */}
      <InfoboxColumn>
        {query.field_population_numbers && (
          <InfoboxSection sectionTitle="Population">
            <p>{populationNumbersMap[query.field_population_numbers]}</p>
          </InfoboxSection>
        )}
        {query.field_conservation_status && (
          <InfoboxSection sectionTitle="Conservation status">
            <p>{conservationStatusMap[query.field_conservation_status]}</p>
          </InfoboxSection>
        )}
        {query.field_wildlife_residency_status && (
          <InfoboxSection sectionTitle="Residency status">
            <p>{residencyStatusMap[query.field_wildlife_residency_status]}</p>
          </InfoboxSection>
        )}
        {query.field_where_to_see && (
          <InfoboxSection sectionTitle="Where to see">
            <p>{query.field_where_to_see}</p>
          </InfoboxSection>
        )}
      </InfoboxColumn>
    </InformationBox>
  );
};

export const query = graphql`
  fragment InformationBoxWildlife on paragraph__information_wildlife {
    id
    drupal_id
    internal {
      type
    }
    field_native_name
    field_scientific_name {
      value
    }
    field_conservation_status
    field_length
    field_population_numbers
    field_weight
    field_where_to_see
    field_wingspan
    field_wildlife_residency_status
    paragraph_type {
      drupal_internal__target_id
    }
  }
`;
